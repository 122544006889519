import * as log from '@/gr/common/log';
import { Result } from '@/gr/common/result';
import { Trend, TrendsApi, TrendConverterFactory, TrendServices, InputDataDefinitionsRepository, ITrendResourceDto, MessageHelper } from '@/apps/timeSeriesViewer';

export class TrendsRepository {
  private _cachedSerializedTrendDtos: { [trendId: string]: string } = {};

  constructor(
    private _inputDataDefinitionRepository: InputDataDefinitionsRepository,
    private _trendsApi: TrendsApi,
    private _trendConverterFactory: TrendConverterFactory
  ) {}

  async loadDtoInto(trend: Trend, trendResourceDto: ITrendResourceDto): Promise<Result<void, string>> {
    const inputDataDefinitionIds = trendResourceDto.trend.dataDefinition.configuredDataDefinitions.map((d) => d.inputDataDefinitionId);

    const inputDefinitions = await this._inputDataDefinitionRepository.getCollectionFromIdDtos(inputDataDefinitionIds);
    if (inputDefinitions.isError) return Result.error(inputDefinitions.error);

    const trendConverter = this._trendConverterFactory.create();
    trendConverter.copyToModel(trendResourceDto, trend, inputDefinitions.value);

    if (trend.id) {
      await this.updateDescription(trend);
      this._cachedSerializedTrendDtos[trend.id] = this.serializeTrend(trend);
    }

    if (trend.isAffectedByRelease()) {
      // special check to notify the user if the trend has been effected by a change in the data definitions
      const releaseNotesDomain = trend.productName === 'Ez2viewAustralia' ? 'ez2viewaustralia' : 'nemreview';
      const releaseNotesUrls = [
        `https://timeline.${releaseNotesDomain}.info/2024/04/28april-trends-enhancements/`,
        `https://timeline.${releaseNotesDomain}.info/2024/10/11october-trends-enhancements/`
      ];

      trend.messages.add(
        MessageHelper.Message.getAnnouncement(
          'The definitions of the series in this trend may have changed due to an update.',
          `Please see our release notes for more information: <br/>
          ${releaseNotesUrls.map((x) => `<a target='_blank' href='${x}'>${x}</a>`).join('<br/>')}`
        ),
        'warning'
      );
    }

    return Result.success();
  }

  save(trend: Trend): { hasTrendChanged: boolean; promise: Promise<Result<void, string>> } {
    const update = trend.apiLinks.update;
    if (!update || trend.id === null) return { hasTrendChanged: false, promise: Promise.resolve(Result.success()) };

    const serializedDto = this.serializeTrend(trend);

    if (this._cachedSerializedTrendDtos[trend.id] === serializedDto) {
      return { hasTrendChanged: false, promise: Promise.resolve(Result.success()) };
    }

    log.information(`Saving trend... ${trend._etag}`);

    const trendId = trend.id;

    return {
      hasTrendChanged: true,
      promise: new Promise<Result<void, string>>((resolve) => {
        this._trendsApi.updateTrend(update, serializedDto).then((updateResult) => {
          updateResult
            .ifSuccess(async (trendPutResult) => {
              trend._etag = trendPutResult._etag ?? null;
              await this.updateDescription(trend);
              if (trend.id) this._cachedSerializedTrendDtos[trend.id] = this.serializeTrend(trend);
              resolve(Result.success());
            })
            .ifError((error) => {
              delete this._cachedSerializedTrendDtos[trendId];
              resolve(Result.error(error));
            });
        });
      })
    };
  }

  private async updateDescription(trend: Trend) {
    const descriptionResult = await this._trendsApi.getDescription(trend.id ?? '');
    descriptionResult
      .ifSuccess((descriptionDto) => {
        trend.description(descriptionDto.html);
      })
      .ifError((error) => {
        log.error(`Unable to update the description : ${error}`);
      });
  }

  private serializeTrend(trend: Trend) {
    const trendConverter = this._trendConverterFactory.create();
    const dto = trendConverter.toDto(trend);
    return JSON.stringify(dto.trend);
  }
}

export class TrendRepositoryFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): TrendsRepository {
    return new TrendsRepository(this._services.inputDataDefinitionsRepository, this._services.trendApi, new TrendConverterFactory(this._trend, this._services));
  }
}
